import { Tabs } from "@pankod/refine-antd";
import { useResource } from "@pankod/refine-core";
import { BannerList } from "./banner";
import { BannerTypeList } from "./banner-type";

export const BannerManagementList: React.FC = () => {
  const { resource: bannerResource } = useResource({
    resourceNameOrRouteName: "banners",
  });
  const { resource: bannerTypeResource } = useResource({
    resourceNameOrRouteName: "banner-types",
  });
  return (
    <Tabs defaultActiveKey="1">
      <Tabs.TabPane tab="Banner" key="1">
        <BannerList name={bannerResource.name} />
      </Tabs.TabPane>
      <Tabs.TabPane tab="Banner Tipi" key="2">
        <BannerTypeList name={bannerTypeResource.name} />
      </Tabs.TabPane>
    </Tabs>
  );
};

import { Form, Input, Modal, ModalProps, FormProps } from "@pankod/refine-antd";

type Props = {
  formProps: FormProps;
  modalProps: ModalProps;
};

export const BannerTypeEdit: React.FC<Props> = ({ formProps, modalProps }) => {
  return (
    <Modal {...modalProps} title="Banner Tipi Düzenle" width={400}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="İsim"
          name="name"
          rules={[
            { required: true, message: "İsim alanı zorunludur" },
            {
              validator: (_, value) => {
                if (!value || value.trim() === "") {
                  return Promise.reject(
                    "Lütfen sadece boşluk karakterlerinden oluşmayan geçerli bir isim giriniz"
                  );
                }
                if (value.includes(" ")) {
                  return Promise.reject(
                    "Banner tipi adında boşluk karakteri kullanılamaz"
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

import {
  Form,
  FormProps,
  Input,
  message,
  Modal,
  Select,
  Upload,
  useSelect,
} from "@pankod/refine-antd";
import { ModalProps } from "@pankod/refine-antd";
import { file2Base64 } from "@pankod/refine-core";
import { useEffect, useState } from "react";
import { Banner } from "interfaces/banners";
import { PlusOutlined } from "@ant-design/icons";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

type Props = {
  formProps: FormProps & { initialValues?: any };
  modalProps: ModalProps;
};

const FontSize = Quill.import("attributors/style/size");
const fontSizes = [
  "7px",
  "7.5px",
  "8px",
  "8.5px",
  "9px",
  "9.5px",
  "10px",
  "10.5px",
  "11px",
  "11.5px",
  "12px",
  "12.5px",
];
FontSize.whitelist = fontSizes;
Quill.register(FontSize, true);

const quillModules = {
  toolbar: [
    [{ font: [] }, { size: FontSize.whitelist }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "super" }, { script: "sub" }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }],
    ["direction", { align: [] }],
    ["clean"],
  ],
};

export const EditBanner: React.FC<Props> = ({ formProps, modalProps }) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [base64Image, setBase64Image] = useState<string | null>(null);
  const [backgroundFileList, setBackgroundFileList] = useState<any[]>([]);
  const [base64BackgroundImage, setBase64BackgroundImage] = useState<
    string | null
  >(null);

  const { selectProps: bannerTypeSelectProps } =
    useSelect<Banner.BannerTypeItem>({
      resource: "banner-types",
      optionLabel: "name",
      optionValue: "id",
      fetchSize: 1000,
    });
  useEffect(() => {
    if (formProps.initialValues) {
      resetForm();
    }
  }, [formProps.initialValues]);

  const resetForm = () => {
    const initialValues = formProps.initialValues || {};
    formProps.form.setFieldsValue(initialValues);

    if (initialValues.backgroundImage) {
      setBackgroundFileList([
        {
          uid: "1",
          name: "Image",
          status: "done",
          url: initialValues.backgroundImage,
        },
      ]);
      setBase64BackgroundImage(initialValues.backgroundImage);
    } else {
      setBackgroundFileList([]);
      setBase64BackgroundImage(null);
    }
    if (initialValues.image) {
      setFileList([
        { uid: "1", name: "Image", status: "done", url: initialValues.image },
      ]);
      setBase64Image(initialValues.image);
    } else {
      setFileList([]);
      setBase64Image(null);
    }
  };

  const handleUploadChange = async ({ fileList: newFileList }: any) => {
    setFileList(newFileList);
    if (newFileList.length > 0) {
      const base64Result = await file2Base64(newFileList[0]);
      setBase64Image(base64Result);
    } else {
      setBase64Image(null);
      formProps.form.setFieldsValue({ image: null });
    }
  };

  const handleBackgroundUploadChange = async ({
    fileList: newBackgroundFileList,
  }: any) => {
    setBackgroundFileList(newBackgroundFileList);
    if (newBackgroundFileList.length > 0) {
      const base64Result = await file2Base64(newBackgroundFileList[0]);
      setBase64BackgroundImage(base64Result);
    } else {
      setBase64BackgroundImage(null);
      formProps.form.setFieldsValue({ backgroundImage: null });
    }
  };

  const onFinish = async (values: Banner.BannerItem) => {
    const formattedValues = {
      ...values,
      image: base64Image,
      backgroundImage: base64BackgroundImage,
    };
    return formProps.onFinish?.(formattedValues);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Yükle</div>
    </div>
  );

  return (
    <Modal
      {...modalProps}
      title="Banner Düzenle"
      width={400}
      onCancel={(e) => {
        modalProps.onCancel?.(e);
        formProps.form?.resetFields();
        resetForm();
      }}
    >
      <Form {...formProps} layout="vertical" onFinish={onFinish}>
        <Form.Item label="Başlık" name="title">
          <ReactQuill theme="snow" modules={quillModules} />
        </Form.Item>
        <Form.Item label="Banner Tipi" name="bannerType">
          <Select
            {...bannerTypeSelectProps}
            defaultValue={formProps.initialValues?.bannerType.id}
          />
        </Form.Item>
        <Form.Item label="İndirim Metni" name="discountText">
          <ReactQuill theme="snow" modules={quillModules} />
        </Form.Item>
        <Form.Item
          label="Ürün Resmi"
          name="image"
          rules={[{ required: true, message: "Ürün Resmi zorunludur." }]}
        >
          <Upload.Dragger
            accept="image/*"
            listType="picture-card"
            fileList={fileList}
            onChange={handleUploadChange}
            beforeUpload={() => false}
          >
            {uploadButton}
          </Upload.Dragger>
        </Form.Item>
        <Form.Item
          label="Arka Plan Resmi"
          name="backgroundImage"
          rules={[{ required: true, message: "Arka Plan Resmi zorunludur." }]}
        >
          <Upload.Dragger
            accept="image/*"
            listType="picture-card"
            fileList={backgroundFileList}
            onChange={handleBackgroundUploadChange}
            beforeUpload={() => false}
          >
            {uploadButton}
          </Upload.Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};

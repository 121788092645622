import { authProvider } from "authProvider";

export const accessControlProvider = {
  can: async ({ resource, action }) => {
    // Kullanıcı yetkilerini kontrol edin
    const user = await authProvider.getUserIdentity();

    // Eğer kullanıcı bilgisi yoksa erişimi reddedin
    if (!user) {
      return { can: false };
    }

    if (
      resource === "contents" &&
      (action === "list" ||
        action === "create" ||
        action === "edit" ||
        action === "delete")
    ) {
      if (
        (user.role.type === "shop-manager" ||
          user.role.type === "sales-manager") &&
        user.ommaShops.length <= 0
      ) {
        return Promise.resolve({
          can: false,
          reason:
            "İçerik yönetimi kısmını görmeniz için bir bayinizin olması lazım.",
        });
      }
    }
    if (
      resource === "catalog-management" &&
      (action === "list" ||
        action === "create" ||
        action === "edit" ||
        action === "delete")
    ) {
      if (
        !(user.role.type === "super-admin" || user.role.type === "system-admin")
      ) {
        return Promise.resolve({
          can: false,
          reason: "Bu işlemi yapmaya yetkiniz yok",
        });
      }
    }
    if (
      resource === "wof/prize" &&
      (action === "list" ||
        action === "create" ||
        action === "edit" ||
        action === "delete")
    ) {
      if (
        !(user.role.type === "super-admin" || user.role.type === "system-admin")
      ) {
        return Promise.resolve({
          can: false,
          reason: "Bu işlemi yapmaya yetkiniz yok",
        });
      }
    }
    if (
      resource === "wof/label" &&
      (action === "list" ||
        action === "create" ||
        action === "edit" ||
        action === "delete")
    ) {
      if (
        !(user.role.type === "super-admin" || user.role.type === "system-admin")
      ) {
        return Promise.resolve({
          can: false,
          reason: "Bu işlemi yapmaya yetkiniz yok",
        });
      }
    }

    if (resource === "banner-management" && action === "list") {
      if (
        !(user.role.type === "super-admin" || user.role.type === "system-admin")
      ) {
        return Promise.resolve({
          can: false,
          reason: "Bu işlemi yapmaya yetkiniz yok",
        });
      }
    }

    return Promise.resolve({ can: true });
  },
};

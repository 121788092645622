import {
  DeleteButton,
  EditButton,
  Icons,
  Image,
  List,
  Table,
  useModalForm,
  useTable,
} from "@pankod/refine-antd";
import { useState } from "react";
import { Banner } from "interfaces/banners";
import { BannerCreate } from "./create";
import { EditBanner } from "./edit";
import { IResourceComponentsProps } from "@pankod/refine-core";
export const BannerList: React.FC<IResourceComponentsProps> = ({ name }) => {
  const { tableProps } = useTable<Banner.BannerItem>({
    resource: name,
  });

  const {
    formProps: createFormProps,
    modalProps: createModalProps,
    show: showCreateModal,
  } = useModalForm({
    resource: name,
    action: "create",
    submitOnEnter: true,
    redirect: false,
    successNotification: {
      type: "success",
      message: "Banner başarıyla oluşturuldu.",
    },
    onMutationSuccess: () => {
      createFormProps.form.resetFields();
    },
  });

  const {
    formProps: editFormProps,
    modalProps: editModalProps,
    show: showEditModal,
  } = useModalForm({
    resource: name,
    action: "edit",
    submitOnEnter: true,
    redirect: false,
    successNotification: {
      type: "success",
      message: "Banner başarıyla güncellendi.",
    },
  });

  return (
    <>
      <List
        title="Banner Yönetimi"
        createButtonProps={{
          type: "primary",
          size: "large",
          shape: "round",
          icon: <Icons.PlusOutlined />,
          children: "Yeni Banner Yayınla",
          onClick: () => showCreateModal(),
        }}
      >
        <Table {...tableProps}>
          <Table.Column<Banner.BannerItem>
            dataIndex="image"
            title="Ürün Resmi"
            key="image"
            render={(value) =>
              value ? (
                <Image src={value} alt="Ürün Resmi" width={100} height={100} />
              ) : (
                <span>Resim Yok</span>
              )
            }
          />
          <Table.Column<Banner.BannerItem>
            dataIndex="backgroundImage"
            title="Arka Plan Resmi"
            key="backgroundImage"
            render={(value) =>
              value ? (
                <Image
                  src={value}
                  alt="Arka Plan Resmi"
                  width={100}
                  height={100}
                />
              ) : (
                <span>Resim Yok</span>
              )
            }
          />
          <Table.Column<Banner.BannerItem>
            dataIndex="title"
            title="Başlık"
            key="title"
            render={(value) => (
              <div dangerouslySetInnerHTML={{ __html: value }} />
            )}
          />

          <Table.Column<Banner.BannerItem>
            dataIndex="discountText"
            title="İndirim Metni"
            key="discountText"
            render={(value) => (
              <div dangerouslySetInnerHTML={{ __html: value }} />
            )}
          />
          <Table.Column<Banner.BannerItem>
            dataIndex="createdAt"
            title="Oluşturulma Tarihi"
            key="createdAt"
            render={(value) => <span>{value}</span>}
          />
          <Table.Column<Banner.BannerItem>
            dataIndex="bannerType"
            title="Banner Tipi"
            key="bannerType"
            render={(value) => <span>{value.name}</span>}
          />
          <Table.Column<Banner.BannerItem>
            title="İşlemler"
            dataIndex="actions"
            render={(_, record) => (
              <>
                <EditButton
                  resourceNameOrRouteName={name}
                  size="small"
                  onClick={() => {
                    showEditModal(record.id);
                  }}
                >
                  Düzenle
                </EditButton>
                <DeleteButton
                  resourceNameOrRouteName={name}
                  size="small"
                  recordItemId={record.id}
                  successNotification={{
                    type: "success",
                    message: "Banner başarıyla silindi.",
                  }}
                >
                  Sil
                </DeleteButton>
              </>
            )}
          />
        </Table>
        <BannerCreate
          formProps={createFormProps}
          modalProps={createModalProps}
        />
        <EditBanner formProps={editFormProps} modalProps={editModalProps} />
      </List>
    </>
  );
};

import {
  DeleteButton,
  EditButton,
  Icons,
  List,
  Table,
} from "@pankod/refine-antd";
import { useModalForm, useTable } from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { Banner } from "interfaces/banners";
import { BannerTypeCreate } from "./create";
import { BannerTypeEdit } from "./edit";
export const BannerTypeList: React.FC<IResourceComponentsProps> = ({
  name,
}) => {
  const { tableProps } = useTable<Banner.BannerTypeItem>({
    resource: name,
    queryOptions: {
      enabled: true,
    },
    metaData: {
      custom: true,
    },
  });

  const {
    formProps: bannerTypeCreateFormProps,
    modalProps: bannerTypeCreateModalProps,
    show: bannerTypeCreateModalShow,
  } = useModalForm({
    resource: name,
    action: "create",
    submitOnEnter: true,
    redirect: false,
    successNotification: {
      type: "success",
      message: "Banner tipi başarıyla oluşturuldu.",
    },
  });

  const {
    formProps: bannerTypeEditFormProps,
    modalProps: bannerTypeEditModalProps,
    show: bannerTypeEditModalShow,
  } = useModalForm<Banner.BannerTypeItem>({
    resource: name,
    action: "edit",
    submitOnEnter: true,
    redirect: false,
    successNotification: {
      type: "success",
      message: "Banner tipi başarıyla güncellendi.",
    },
  });
  return (
    <>
      <List
        resource={name}
        title="Banner Tipleri"
        createButtonProps={{
          type: "primary",
          size: "large",
          shape: "round",
          icon: <Icons.PlusCircleFilled />,
          onClick: () => bannerTypeCreateModalShow(),
          children: "Yeni Banner Tipi Oluştur",
        }}
      >
        <Table {...tableProps}>
          <Table.Column dataIndex="name" title="İsim" key="name" sorter />
          <Table.Column<Banner.BannerTypeItem>
            title="İşlemler"
            dataIndex="actions"
            render={(_, record) => (
              <>
                <EditButton
                  resourceNameOrRouteName={name}
                  size="small"
                  onClick={() => {
                    bannerTypeEditModalShow(record.id);
                  }}
                >
                  Düzenle
                </EditButton>
                <DeleteButton
                  resourceNameOrRouteName={name}
                  size="small"
                  recordItemId={record.id}
                  successNotification={{
                    type: "success",
                    message: "Banner başarıyla silindi.",
                  }}
                >
                  Sil
                </DeleteButton>
              </>
            )}
          />
        </Table>
        <BannerTypeCreate
          formProps={bannerTypeCreateFormProps}
          modalProps={bannerTypeCreateModalProps}
        />
        <BannerTypeEdit
          formProps={bannerTypeEditFormProps}
          modalProps={bannerTypeEditModalProps}
        />
      </List>
    </>
  );
};

// #region Global Imports
import React, { FC, useEffect, useLayoutEffect } from "react";
import { Button, Slider, Tag, Tooltip, Icons } from "@pankod/refine-antd";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
// #endregion Global Imports

// #region Local Imports
import { Modal } from "./components";
import { useAsset } from "components/formbuilder/components/asset/useAsset";
// #region Local Imports

// #region Interface Imports
import { SelectAsset, AssetNamePiece } from "./IAsset";
// #endregion Interface Imports

const { CheckableTag } = Tag;

type Props = {
  onChange?: (value: SelectAsset) => void;
  value?: SelectAsset;
};

const reorder = (
  list: AssetNamePiece[],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const Asset: FC<Props> = ({ value, onChange }) => {
  const [visibleModal, setVisibleModal] = React.useState(false);

  const {
    activeAsset,
    activeAssetName,
    setActiveAsset,
    setNewAsset,
    toggleAssetNamePiece,
    setAssetNamePieces,
  } = useAsset(value);

  const [scale, setScale] = React.useState<number>((value && value.scale) || 0);

  // activeAssetName değiştiğinde onChange'i çağır
  useEffect(() => {
    if (onChange && activeAsset?.asset && activeAssetName) {
      onChange({
        name: activeAssetName,
        path: activeAsset.asset.path,
        scale,
      });
    }
  }, [activeAssetName]); // activeAssetName dependency olarak eklendi

  const renderModal = () => {
    if (visibleModal) {
      return (
        <Modal
          title="Ürün Seçin"
          visible
          width="1000px"
          onCancel={() => {
            setActiveAsset(undefined);
            setVisibleModal(false);
          }}
          okButtonProps={{
            disabled: !activeAsset?.asset,
          }}
          onOk={() => {
            // select asset
            if (onChange && activeAsset?.asset && activeAssetName) {
              onChange({
                name: activeAssetName,
                path: activeAsset.asset.path,
                scale,
              });
            }
            // close modal
            setVisibleModal(false);
          }}
          onClickItem={(asset: SelectAsset) => {
            setNewAsset(asset);
          }}
          selectedItem={activeAsset?.asset}
          cancelText="İptal"
          okText="Ürünü Seç"
        />
      );
    }

    return null;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      activeAsset?.assetNamePieces ?? [],
      result.source.index,
      result.destination.index
    );

    setAssetNamePieces(items);
  };

  const sliderOnChange = (newScale: number) => {
    setScale(newScale);
    if (onChange && activeAsset?.asset && activeAssetName) {
      onChange({
        name: activeAssetName,
        path: activeAsset?.asset.path,
        scale: newScale,
      });
    }
  };

  const onTagToggle = (index: number, checked: boolean) => {
    toggleAssetNamePiece(index, checked);
  };

  return (
    <>
      {renderModal()}
      <Button
        style={{ marginBottom: 9 }}
        onClick={() => setVisibleModal(true)}
        type="primary"
      >
        {(activeAsset?.asset && `Seçildi: ${activeAsset.asset.name}`) ||
          "Ürün Seçin"}
      </Button>
      {activeAsset && (
        <>
          <p data-tour="step-1">
            <NameTitle />
            <DroppableContainer onDragEnd={onDragEnd}>
              {activeAsset.assetNamePieces?.map((piece, index) => (
                <DraggableTag
                  onTagToggle={onTagToggle}
                  index={index}
                  piece={piece}
                />
              ))}
            </DroppableContainer>
          </p>
          <ScaleSlider onChange={sliderOnChange} scale={scale} />
        </>
      )}
    </>
  );
};

const DroppableContainer: FC<{
  onDragEnd(result: DropResult): void;
}> = ({ onDragEnd, children }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ display: "flex", marginTop: 6 }}
          >
            {children}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const DraggableTag: FC<{
  onTagToggle(index: number, checked: boolean): void;
  index: number;
  piece: AssetNamePiece;
}> = ({ onTagToggle, index, piece }) => {
  return (
    <Draggable key={piece.value} draggableId={piece.value} index={index}>
      {(draggableProvided) => (
        <div
          ref={draggableProvided.innerRef}
          {...draggableProvided.draggableProps}
          {...draggableProvided.dragHandleProps}
        >
          <CheckableTag
            checked={piece.checked}
            onChange={(checked) => onTagToggle(index, checked)}
          >
            {piece.value}
          </CheckableTag>
        </div>
      )}
    </Draggable>
  );
};

const ScaleSlider: FC<{
  onChange(newValue: number): void;
  scale: number;
}> = ({ onChange, scale }) => {
  return (
    <p
      style={{
        width: 150,
      }}
      data-tour="step-2"
    >
      Boyut
      <Slider
        defaultValue={scale}
        style={{ width: 150 }}
        min={-0.2}
        max={0.2}
        step={0.1}
        onChange={onChange}
      />
    </p>
  );
};

const NameTitle = () => {
  const { t } = useTranslation();

  return (
    <div>
      Ürün İsmi
      <Tooltip title={t("common:ProductNameConfig")}>
        <Icons.InfoCircleOutlined style={{ marginLeft: 4 }} />
      </Tooltip>
    </div>
  );
};

import {
  Input,
  Form,
  Modal,
  Icons,
  Upload,
  message,
  useSelect,
  Select,
} from "@pankod/refine-antd";
import { file2Base64 } from "@pankod/refine-core";
import { Banner } from "interfaces/banners";
import { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
export const BannerCreate = ({ formProps, modalProps }) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [backgroundFileList, setBackgroundFileList] = useState<any[]>([]);
  const [base64Image, setBase64Image] = useState<string | null>(null);
  const [base64BackgroundImage, setBase64BackgroundImage] = useState<
    string | null
  >(null);
  const FontSize = Quill.import("attributors/style/size");
  const fontSizes = Array.from(
    { length: (12.5 - 7) / 0.5 + 1 }, // 12 element (7'den 12.5'e)
    (_, index) => `${7 + index * 0.5}px`
  );
  FontSize.whitelist = fontSizes;
  Quill.register(FontSize, true);

  // Quill modül konfigürasyonu
  const quillModules = {
    toolbar: [
      [{ font: [] }, { size: FontSize.whitelist }], // Font ve size seçenekleri
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "super" }, { script: "sub" }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["direction", { align: [] }],
      ["clean"],
    ],
  };

  const { selectProps: bannerTypeSelectProps } =
    useSelect<Banner.BannerTypeItem>({
      resource: "banner-types",
      optionLabel: "name",
      optionValue: "id",
      defaultValue: undefined,
    });

  const handleBackgroundUploadChange = async ({
    fileList: newBackgroundFileList,
  }: any) => {
    if (newBackgroundFileList.length > 1) {
      message.error("Yalnızca 1 fotoğraf yükleyebilirsiniz.");
      return;
    }

    setBackgroundFileList(newBackgroundFileList);

    if (newBackgroundFileList.length > 0) {
      const base64Result = await file2Base64(newBackgroundFileList[0]);
      setBase64BackgroundImage(base64Result);
    } else {
      setBase64BackgroundImage(null);
    }
  };

  const handleUploadChange = async ({ fileList: newFileList }: any) => {
    if (newFileList.length > 1) {
      message.error("Yalnızca 1 fotoğraf yükleyebilirsiniz.");
      return;
    }

    setFileList(newFileList);

    if (newFileList.length > 0) {
      const base64Result = await file2Base64(newFileList[0]);
      setBase64Image(base64Result);
    } else {
      setBase64Image(null);
    }
  };

  const resetState = () => {
    setFileList([]);
    setBase64Image(null);
    setBackgroundFileList([]);
    setBase64BackgroundImage(null);
  };

  const onFinish = async (values: any) => {
    const formattedValues = {
      ...values,
      image: base64Image || "",
      backgroundImage: base64BackgroundImage || "",
    };
    resetState();
    return formProps.onFinish?.(formattedValues);
  };

  const uploadButton = (
    <div>
      <Icons.PlusOutlined />
      <div style={{ marginTop: 8 }}>Yükle</div>
    </div>
  );

  return (
    <Modal
      {...modalProps}
      title="Banner Oluştur"
      width={400}
      onCancel={() => {
        modalProps.onCancel?.();
        resetState();
        formProps.form?.resetFields();
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={onFinish}
        onReset={resetState}
      >
        <Form.Item<Banner.BannerItem>
          label="Başlık"
          name="title"
          rules={[{ required: true, message: "Başlık zorunludur." }]}
        >
          <ReactQuill
            theme="snow"
            modules={quillModules}
            placeholder="Tavsiye edilen font boyutu 12.5px."
          />
        </Form.Item>
        <Form.Item<Banner.BannerItem>
          label="İndirim Yazısı"
          name="discountText"
          rules={[{ required: true, message: "İndirim Yazısı zorunludur." }]}
        >
          <ReactQuill
            theme="snow"
            modules={quillModules}
            placeholder="Tavsiye edilen font boyutu 7.5px.Lütfen değiştirmeyiniz."
          />
        </Form.Item>
        <Form.Item<Banner.BannerItem>
          label="Banner Tipi"
          name="bannerType"
          rules={[
            { required: true, message: "Banner Tipi seçimi zorunludur." },
          ]}
        >
          <Select {...bannerTypeSelectProps} />
        </Form.Item>
        <Form.Item<Banner.BannerItem>
          label="Ürün Resmi"
          name="image"
          rules={[{ required: true, message: "Resim yüklemek zorunludur." }]}
        >
          <Upload.Dragger
            name="files"
            accept="image/*"
            listType="picture-card"
            fileList={fileList}
            onChange={handleUploadChange}
            // maxCount={1}
            beforeUpload={() => false}
          >
            {uploadButton}
          </Upload.Dragger>
        </Form.Item>
        <Form.Item<Banner.BannerItem>
          label="Arka Plan Resmi"
          name="backgroundImage"
          rules={[{ required: true, message: "Arka Plan Resmi zorunludur." }]}
        >
          <Upload.Dragger
            name="files"
            accept="image/*"
            listType="picture-card"
            fileList={backgroundFileList}
            onChange={handleBackgroundUploadChange}
            // maxCount={1}
            beforeUpload={() => false}
          >
            {uploadButton}
          </Upload.Dragger>
        </Form.Item>
      </Form>
    </Modal>
  );
};
